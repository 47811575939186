import { defineStore } from 'pinia';
import { NotificationType } from '@/js/quests/dashboard/types/types';
import { getTrans } from '@/js/helpers/getTrans';
import { BINANCE_JOIN_LINK } from '@/js/quests/dashboard/constants';

const loggedOutNotifications: NotificationType[] = [
    {
        title: getTrans('header.notificationTooltipTitle3', 'Grab Binance $600 Bonus'),
        subtitle: getTrans('header.notificationTooltipDescription3', 'Claim your $600 Welcome Voucher for completing simple tasks on Binance.'),
        img: 'assets/missions/notifications/reward.svg',
        link: BINANCE_JOIN_LINK,
    },
    {
        title: getTrans('quests-dashboard.notificationTitle1', 'You Just Got 50 Bits!'),
        subtitle: getTrans('quests-dashboard.notificationSubtitle1', 'Awesome! Start the first Mission & collect more rewards.'),
        img: 'assets/missions/notifications/bits-earned.svg',
    },
    {
        title: getTrans('quests-dashboard.notificationTitle2', 'Claim NFT Certificate'),
        subtitle: getTrans('quests-dashboard.notificationSubtitle2', 'Finish the Official Web3 Exam & mint your free NFT.'),
        img: 'assets/missions/notifications/nft-certificate-issued.svg',
    },
];

interface NavigationStoreState {
    showSidebar: boolean;
    showMobileStreak: boolean;
    showMobileProfile: boolean;
    showMenuCard: boolean;
    showStreakTippyFunction: () => void;
    notifications: {
        loggedOutNotifications: NotificationType[],
    },
}
export const useNavigationStore = defineStore('NavigationStore', {
    state: (): NavigationStoreState => ({
        showSidebar: false,
        showMobileStreak: false,
        showMobileProfile: true,
        showMenuCard: false,
        showStreakTippyFunction: null,
        notifications: {
            loggedOutNotifications,
        },
    }),
});
